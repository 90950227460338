/**
 * Composable for sharing header height between components. Useful for when the header is sticky, and you have other sticky content on the page that you want to offset.
 *
 * Passing a ref will _write_ the value on mount, while not passing a ref will _read_ the value.
 */
export function useHeaderHeight(): ComputedRef<string>
export function useHeaderHeight(ref: Ref<HTMLElement | null>): void
export function useHeaderHeight(ref?: Ref<HTMLElement | null>) {
  const value = useState('header-height', () => 0)

  if (ref) {
    onMounted(() => {
      if (ref?.value) {
        value.value = ref.value.clientHeight
      }
    })
  }
  else {
    return computed(() => `${value.value}px`)
  }
}
