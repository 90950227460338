import type { Product, ProductVariant } from '~/utils/crystallize/product'
import { truthy } from '#shared/filter'
import { useCart } from './useCart'

type CartItemWithMetadata = CartItemDto & {
  variant?: ProductVariant
  product?: Product
}

export function useCartMetadata() {
  const cart = useCart()
  const catalogue = useProductCatalogue()

  const items = computed<CartItemWithMetadata[]>(() => cart.data.value?.cartItems?.map((item: CartItemWithMetadata) => {
    let product: Product | undefined
    let variant: ProductVariant | undefined

    const products = [
      ...(catalogue.data.value?.products ?? []),
      catalogue.data.value?.highlighted,
    ].filter(truthy)

    for (const p of products) {
      variant = p.variants.find(v => v.sku === item.productId)
      if (variant) {
        product = p
        break
      }
    }

    return {
      ...item,
      variant,
      product,
    }
  }) ?? [])

  return {
    items,
    size: computed(() => items.value.length),
    totalPrice: computed(() => {
      let exVat = 0
      let incVat = 0
      for (const item of items.value) {
        if (item.variant) {
          incVat += item.amount * item.variant.price
          exVat += item.amount * getExVatPrice(
            item.variant.price,
            item.variant.components.effectiveVatPercent ?? 0,
          )
        }
      }

      return {
        exVat,
        incVat,
      }
    }),
  }
}
